.App {
  height: calc(100vh - 25vmin);
  text-align: center;
}

html {
  background-color: #10113d;
}

.App-logo {
  height: 30vmin;

  pointer-events: none;
}
h1 {
  font-size: 1.8em;
  margin: 0;
}

h2 {
  font-size: 1em;
  margin: 0;
}

.ultra-cls {
  position: absolute;
  width: 100vw;
  left: 0;
  height: 100vh;
  object-fit: cover;
  background-repeat: no-repeat;
  z-index: 1;
  border-right: #61dafb solid 10px;
}

.sebas {
  max-height: 200px;
  margin-top: -20vh;
  margin-right: -15vw;
}

.align-header {
  max-width: 300px;
  margin-top: 0vh;
  margin-bottom: 0;
  text-align: start;
  padding: 10px;
}

.underline {
  text-decoration: underline;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7px);
  }
}

.bounce {
  display: inline-block;
  animation: bounce 5s infinite;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%); /* Empieza fuera de la pantalla por la izquierda */
  }
  to {
    transform: translateX(0); /* Termina alineado con el borde izquierdo de la pantalla */
  }
}

.moving-text {
  display: inline-block;
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  animation: slideIn 3s ease-out; /* La animación dura 3 segundos y se desaceleclosera hacia el final */
}

.burger-menu {
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.burger-menu {
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 4;
}

.modal-content {
  background-color: #10113d;
  margin: auto;
  padding: 20px;
  width: 100%;
  height: calc(100vh - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.close-btn {
  color: white !important;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-btn:hover,
.close-btn:focus {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.close-btn2 {
  position: fixed;
  top: 22px;
  right: 18px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  padding: 0;
  margin-left: auto !important;
  padding-right: 20px;
  z-index: 6000 !important;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 5vh 5vw;
}

ul li a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
}

ul li a:hover {
  color: #f7c800;
}

.actions-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #f7c800;
  border-radius: 20px;
  color: white;
  padding: 0 10px !important;
  font-weight: 800;
  text-decoration: none;
  margin-right: 20px !important;
  height: 30px !important;
}

.white {
  color: white !important;
  text-decoration: none !important;
}

.nav-header {
  text-align: center;
  color: white !important;
}

.nav-header:hover {
  color: white;
  opacity: 0.5 !important;
}

.logo-main {
  height: 60px;
  margin-left: 20px;
}

.logo-main2 {
  height: 60px;
}

.logo-loader {
  height: 12vmax;
}
.actions-container {
  display: flex;
  width: 100vw;
  height: 90px;
  justify-content: center;
  align-items: center;
  background-color: #10113d;
  z-index: 7000000000000;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100vw;
  position: fixed;
  height: 90px;
  margin-left: auto;
  max-width: 1200px;
}

.title {
  margin-top: 1vh;
  max-width: 420px;
  display: inline-block;
}

.animated-text2 {
  font-size: 0.9em;
  padding: 20px 0;
  color: white;
  animation: fade 2s infinite alternate;
}

.animated-text {
  font-size: 1.8em;
  color: #f7c800;
  animation: fade 2s infinite alternate;
}

@keyframes fade {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.3;
  }
}

.title2 {
  margin-top: 1vh;
  margin-bottom: 20vh;
  display: inline-block;
}

.container-cbn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: #f7c800;
  padding-bottom: 30px;
  border-radius: 20px;
  box-shadow: 2px 2px 7px #0129398a !important;
  border-right: #f7c800 solid 10px;
}

.loader {
  width: 20px;
  height: 20px;
  border: 5px solid #f3f3f321; /* Light grey border */
  border-top: 5px solid #f7c800; /* Blue border */
  border-radius: 50%;
  animation: spin 0.9s linear infinite;
}

.loader-logo {
  position: absolute;
  top: 5vw;
  left: 5vw;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(310deg);
    width: 35px;
    height: 35px;
  }
  50% {
    transform: rotate(320deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.avatar {
  font-weight: 700;
  font-size: 2em;
  max-width: 140px;
  padding: 0 !important;
  text-align: center;
  margin: 0;
  background-color: #08080357;
  border-radius: 20px;
}

.notification-wrp {
  display: flex;
  align-items: center;
  width: 100vw;
  position: fixed;
  background-color: #f7c800;
  top: 90px;
  left: 0;
  gap: 0px;
  z-index: 2;
}

/* src/Notification.css */
.notification {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100vw;
  justify-content: space-between;
  background-color: #f7c800;
  color: #000000;
  max-width: 1200px;
  padding: 20px 0px;
  text-align: center;
  margin: 0 auto;
  gap: 0px;
}

.notification p {
  margin: 0;
}

.notification a {
  text-decoration: underline;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #10113d;
  font-weight: bold;
  padding: 0;
  margin-left: auto !important;
  padding-right: 39px;
}

.link-cls {
  color: white !important;
  font-weight: 700 !important;
}

.loader-wrapper {
  background-color: #10113d;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}
.loader-semi {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  gap: 50px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: bounce 10s linear;
  }
}

.App-header {
  height: calc(100vh - 200px);
  background-color: #10113d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.entrevista-cls {
  padding: 0px 20px;
  color: white;
}

.crane {
  position: fixed;
  top: -280px;
  left: -110px;
  width: 220px;
  height: 120vh;
}

.arm {
  position: absolute;
  top: 100px;
  left: 50%;
  width: 6px;
  height: 120vh;
  background-color: #f7ca003b;
  transform: translateX(-50%);
}

.rope {
  position: absolute;
  left: 50%;
  top: 0;
  width: 20px;
  height: 200px;
  background-color: #333;
  transform: translateX(-50%);
}

.hook {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 120px;
  height: 200vh;
  background-color: #10113d;
  transform: translateX(-50%);
  border-radius: 50%;
  animation: lift-load 1s infinite alternate;
}

.load {
  position: absolute;
  top: 30px;
  left: 50%;
  width: 30px;
  height: 30px;
  background-color: #ffffff00;
  transform: translateX(-50%);
  animation: lift-load 4s infinite alternate;
}

.burger-btn {
  display: none;
}

@keyframes lift {
  0% {
    height: 100px;
  }
  100% {
    height: 50px;
  }
}

@keyframes lift-load {
  0% {
    top: 0px;
  }
  100% {
    top: 130vh;
  }
}

.flexer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3vw;
  height: 100%;
  margin-left: -200px;
}

.navbers {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3vw;
  height: 100%;
}

@media screen and (max-width: 900px) {
  .navbers {
    display: none;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    height: 100%;
  }
  .actions-container {
    display: flex;
    width: 100vw;
    height: 70px;
    justify-content: center;
    align-items: center;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 100vw;
    position: fixed;
    height: 70px;
    z-index: 2;
    margin-left: auto;
    max-width: 1200px;
  }
  .entrevista-cls {
    padding: 0vw 13vw 3vw 13vw;
  }

  .notification-wrp {
    top: 0px;
    left: 0;
  }
  .close-btn {
    display: flex;
    justify-content: flex-start;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    margin-top: -20px;
  }
  .logo-loader {
    height: 150px;
  }
  .loader-semi {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    gap: 50px;
    margin-top: -10vh;
  }
  .logo-main {
    height: 50px;
  }
  .notification {
    flex-direction: column-reverse;
    width: 100vw;
    background-color: #f7c800;
    color: #000000;
    position: fixed;
    top: 70px;
    left: 0;
    padding: 10px 0px 5px 0px;
  }

  .burger-btn {
    display: flex;
    height: 50px;
    margin-right: 20px;
    background-color: transparent;
    border: none;
  }

  .burger-main {
    height: 50px;
  }

  .title {
    margin-top: 1vh;
    max-width: 380px;
    display: inline-block;
  }
  .title2 {
    margin-right: -30vh;
    margin-top: -20vh;
    margin-bottom: 20vh;
    display: inline-block;
  }
  .ultra-cls {
    position: absolute;
    width: 100vw;
    bottom: 0;
    left: 0;
    object-fit: cover;
    background-repeat: no-repeat;
    z-index: 1;
    border-right: none;
  }

  .actions-btn {
    font-size: 0.8em;
    margin-right: 0px !important;
  }
  .sebas {
    max-height: 200px;
    margin-right: 0;
  }
  .App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .container-cbn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    padding: 20px;
    border-radius: 20px;
    margin-left: 0;
  }
  h2 {
    font-size: 1em;
    margin: 0;
  }
  p {
    padding: 0 10px 0 0px;
  }
}
